import './SectionContactForm.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function SectionContactForm() {
    const { t } = useTranslation();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [services, setServices] = useState([]);
    const [formule, setFormule] = useState([]);
    const [delay, setDelay] = useState([]);
    const [details, setDetails] = useState('');

    const handleCheckboxChange = (e, setter) => {
        const { value, checked } = e.target;
        if (checked) {
            setter(prev => [...prev, value]);
        } else {
            setter(prev => prev.filter(val => val !== value));
        }
    };

    function SendMail(e) {
        e.preventDefault();

        // Construire l'URL mailto:
        const mailtoLink = `mailto:hello@stephane-duverney.fr?subject=Contact Form Submission&body=Hello Stéphane,
        
I contacted you because I would like to have a quote for a project about:

${encodeURIComponent(details)}

You can contact me with the following information:
---------------------------
Full Name: ${encodeURIComponent(fullName)}
Email: ${encodeURIComponent(email)}
Services asked: ${encodeURIComponent(services.join(', '))}
Formule asked: ${encodeURIComponent(formule.join(', '))}
Delay needed: ${encodeURIComponent(delay.join(', '))}
`.replace(/\n/g, '%0D%0A');

        // Ouvrir le client de messagerie
        window.location.href = mailtoLink;
    }

    return (
        <section className="section_contact_form">
            <div className='section_contact_form_container'>
                <div className='section_contact_form_container_title_container'>
                    <h2 className='section_contact_form_container_title'>
                        {t('h3i4j5k6l7')}
                    </h2>
                    <p className='section_contact_form_container_subtitle'>
                        {t('f3g4h5i6j7')} <span>{t('k3l4m5n6o7')}</span> {t('z3a4b5c6d7')}
                    </p>
                    <form className='section_contact_form_container_form' onSubmit={SendMail}>
                        <input
                            type='text'
                            placeholder={t('t3u4v5w6x7')}
                            required
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                        <input
                            type='email'
                            placeholder={t('Email')}
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className='section_contact_form_container_form_checkbox_frame'>
                            <div className='section_contact_form_container_form_checkbox'>
                                <input type='checkbox' name='service[]' id='1' value='Vitrine' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='1'>{t('Vitrine')}</label>
                                <input type='checkbox' name='service[]' id='2' value='E-Commerce' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='2'>{t('b4c5d6e7f8')}</label>
                                <input type='checkbox' name='service[]' id='3' value='Services' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='3'>{t('k4l5m6n7o8')}</label>
                                <input type='checkbox' name='service[]' id='4' value='Other' onChange={(e) => handleCheckboxChange(e, setServices)} />
                                <label htmlFor='4'>{t('x4y5z6a7b8')}</label>
                            </div>
                            <div className='section_contact_form_container_form_checkbox'>
                                <input type='checkbox' name='formule[]' id='5' value='Design & Dev' onChange={(e) => handleCheckboxChange(e, setFormule)} />
                                <label htmlFor='5'>{t('h4i5j6k7l8')}</label>
                                <input type='checkbox' name='formule[]' id='6' value='Only Dev' onChange={(e) => handleCheckboxChange(e, setFormule)} />
                                <label htmlFor='6'>{t('q4r5s6t7u8')}</label>
                                <input type='checkbox' name='formule[]' id='7' value='Only Design' onChange={(e) => handleCheckboxChange(e, setFormule)} />
                                <label htmlFor='7'>{t('f4g5h6i7j8')}</label>
                            </div>
                            <div className='section_contact_form_container_form_checkbox'>
                                <input type='checkbox' name='delay[]' id='8' value='Urgent Delay' onChange={(e) => handleCheckboxChange(e, setDelay)} />
                                <label htmlFor='8'>{t('p4q5r6s7t8')}</label>
                                <input type='checkbox' name='delay[]' id='9' value='No stress' onChange={(e) => handleCheckboxChange(e, setDelay)} />
                                <label htmlFor='9'>{t('c4d5e6f7g8')}</label>
                                <input type='checkbox' name='delay[]' id='10' value='Just for curiosity' onChange={(e) => handleCheckboxChange(e, setDelay)} />
                                <label htmlFor='10'>{t('l4m5n6o7p8')}</label>
                            </div>
                        </div>
                        <textarea
                            placeholder={t('d5e6f7g8h9')}
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                        />
                        <button type='submit'>{t('m5n6o7p8q9')}
                            <img src="/svg/icon_send.svg" alt="icon of paper plane" />
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default SectionContactForm;
