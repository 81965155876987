import './SectionAbout.css';
import ButtonContact from '../button/ButtonContact';
import { useTranslation } from 'react-i18next';



function SectionAbout() {
    const { t } = useTranslation();
    return (
        <section id='about' className='section_about'>
            <div className='section_about-container'>
                <div className='section_about-container_left'>
                    <h2 className='section_about-container_left_title'>
                        {t('u1v2w3x4y5')}
                    </h2>
                    <div>
                        <div className='section_about-container_left_name'>
                            {t('e1f2g3h4i5')}
                        </div>
                        <div className='section_about-container_left_job-title'>
                            {t('o1p2q3r4s5')}
                        </div>
                    </div>
                </div>
                <div className='section_about-container_right'>
                    <p>
                        {t('y1z2a3b4c5')}
                    </p>
                </div>
            </div>
            <div className='section_about-container_right_btn'>
                <ButtonContact />
            </div>
        </section>
    );
}

export default SectionAbout;