import './SectionCover.css';
import { useTranslation } from 'react-i18next';

function SectionCover() {
    const { t } = useTranslation();
    const handleSendEmail = () => {

        const email = 'hello@stephane-duverney.fr';
        const userMessage = document.querySelector('#emailMessageInput').value;
        const fullMessage = `Hello Stéphane !\n\n${userMessage}\n\nBye`;
        const mailtoLink = `mailto:${email}?subject=Question from the website&body=${encodeURIComponent(fullMessage)}`;
        window.location.href = mailtoLink;
    };

    return (
        <section id='home' className="section_cover">
            <div className='section_cover_message-bar_container'>
                <h1 className='section_cover_message-bar_title'>{t('a1b2c3d4e5')}</h1>
                <img className='section_cover_message-bar_icon' src="/svg/icon_star.svg" alt=""/>
            </div>
            <div className='section_cover_message-bar'>
                <input type="text" id="emailMessageInput" placeholder={t('k1l2m3n4o6')}/>
                <button type="button" onClick={handleSendEmail}>
                    <img src="/svg/icon_send-message.svg" alt="button for sending message"/>
                </button>
            </div>
            <div className='section_cover-scroll'>
                <div>{t('k1l2m3n4o5')}</div>
                <img className='section_cover-scroll-arrow' src="/svg/icon_arrow-down.svg" alt="arrow icon showing bottom" />
            </div>
        </section>
    );
}

export default SectionCover;