import './AppChat.css'

function AppChat() {
    return (
        <div className='app_chat'>
            <img src="/svg/icon_chat.svg" alt="button of chat application" />
        </div>
    )
}

export default AppChat;