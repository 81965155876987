import './SectionWebApp.css'
import { useTranslation } from 'react-i18next';

function SectionWebApp() {
    const { t } = useTranslation();
    return (
        <section className="section_webapp">
            <div className="section_webapp_container">
                <div className='section_web_container_title'>
                    <img src="/svg/section_webdesign_title.svg" alt="" />
                </div>
                <div className='section_web_container_sub-title'>
                    <div>
                    </div>
                </div>
                <div className='section_web_container_text-container'>
                    {/* <div className='section_web_container_text-container_content-left'>
                        <div className='section_web_container_text-container_content_title'>My process</div>
                        <div>
                            My process begins with thorough research to understand the users, their needs, and the problem space. From there, I brainstorm and design user-centered solutions, creating intuitive, functional layouts. I develop interactive prototypes to gather feedback, iterating to refine the design. With full-stack skills, I seamlessly transition to development, writing clean, efficient code to bring the designs to life. Post-launch, I continuously monitor performance and user feedback, making adjustments to ensure the product remains effective and user-friendly.                        </div>
                    </div> */}
                    <div className='section_web_container_text-container_content-right'>
                        <h2 className='section_web_container_text-container_content_title'>{t('i1j2k3l4m5')}</h2>
                        <p>
                            {t('i1j2k3l4m6')}
                        </p>
                    </div>
                </div>
                {/* <div className='section_web_container_graph'>
                    <img src="/svg/icon_graph.svg" alt="" />
                </div> */}
            </div>
        </section>
    );
}

export default SectionWebApp;