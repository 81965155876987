import { useState, useEffect } from 'react';

// Fonction pour récupérer un cookie par son nom
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

// Fonction pour définir un cookie avec des options (par exemple, expiration)
function setCookie(name, value, options = {}) {
    let cookieString = `${name}=${value}; path=/`;

    if (options.expires) {
        const date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000)); // options.expires en jours
        cookieString += `; expires=${date.toUTCString()}`;
    }

    document.cookie = cookieString;
}

function useCookie(cookieName, options = {}) {
    const [cookieValue, setCookieValue] = useState(() => {
        return getCookie(cookieName); // Utilisation correcte de getCookie
    });

    useEffect(() => {
        setCookie(cookieName, cookieValue, options); // Utilisation correcte de setCookie
    }, [cookieName, cookieValue, options]);

    return [cookieValue, setCookieValue];
}

export default useCookie;
