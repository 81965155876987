import './footer.css'
import { useTranslation } from 'react-i18next';
import AppLanguageSwitcher from '../app/AppLanguageSwitcher'

function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="footer">
            <div className='footer_container'>
                <div className='footer_content_left'>
                    <p className='footer_content_left_title'>
                        {t('g5h6i7j8k9')}
                    </p>
                    <p>
                        {t('description')}
                    </p>
                </div>
                <div className='footer_content_right'>
                    <a href='https://www.instagram.com/expience/' target='_blank' rel='noreferrer' aria-label='link to the instagram profil'>
                        <img src="/svg/logo_instagram.svg" alt="" />
                    </a>
                    <a href='https://www.behance.net/DuverneyDesign' target='_blank' rel='noreferrer' aria-label='link to the behance profil'>
                        <img src="/svg/logo_behance.svg" alt="" />
                    </a>
                </div>
            </div>
            <div className='footer_languages'> 
                <AppLanguageSwitcher />
            </div>
            <div className='footer_watermark'>
                <p>
                    {t('a5b6c7d8e9')}
                </p>
            </div>
        </footer>
    );
}

export default Footer;