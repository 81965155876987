function scrollTo(e) {
    e.preventDefault();
    const target = e.target.getAttribute('href');
    const element = document.querySelector(target);
  
    const offset = 100; 
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
  
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }

export default scrollTo;