import React, { useEffect } from 'react';
import './navbar.css';
import scrollTo from '../utils/scrollTo';
import { useTranslation } from 'react-i18next';

let lastScrollTop = 0; // Variable to store the last scroll position

function Navbar() {
  const { t } = useTranslation();

  // Function to handle menu toggle
  const handleMenu = () => {
    const navbar = document.getElementById('navbar-inner');
    const navbarToggle = document.getElementById('navbar-toggle');
    if (navbar && navbarToggle) {
      navbar.classList.toggle('active');
      navbarToggle.classList.toggle('active');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      let currentScroll = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScroll > lastScrollTop) {
        // Scrolling down
        navbar.classList.add('navbar-hidden');
      } else {
        // Scrolling up
        navbar.classList.remove('navbar-hidden');
      }

      // Update last scroll position
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    // Adding the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div id="navbar-inner" className="navbar-inner">
          <div className="navbar-logo">
            <a href="#home" onClick={(e) => { scrollTo(e); handleMenu(); }}>{t('menu-home')}</a>
          </div>
          <ul className="navbar-pages">
            <li>
              <a href="#about" onClick={(e) => { scrollTo(e); handleMenu(); }}>{t('menu-about')}</a>
            </li>
            <li>
              <a href="#process" onClick={(e) => { scrollTo(e); handleMenu(); }}>{t('menu-process')}</a>
            </li>
            <li>
              <a className="navbar-contact" href="#contact" onClick={(e) => { scrollTo(e); handleMenu(); }}>{t('menu-contact')}</a>
            </li>
          </ul>
        </div>
      </nav>
      <button id="navbar-toggle" className="navbar-toggle" onClick={handleMenu}>
        {t('menu-toggle')}
      </button>
    </>
  );
}

export default Navbar;
