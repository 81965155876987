import './ButtonContact.css'
import { useTranslation } from 'react-i18next';

function ButtonContact() {
    const { t } = useTranslation();
    return (
        <div className='button_contact'>
            <a href="mailto:hello@stephane-duverney.fr?subject=Question about website&body=Your message">
                {t('x3y4z5a6b7')}
            </a>
        </div>
    );
}

export default ButtonContact;