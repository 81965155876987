import React, { useEffect, useState } from 'react';
import './i18n';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

// Importing styling
import './App.css';

// Importing components
import Navbar from './components/navbar/navbar';
import SectionCover from './components/section/SectionCover';
import SectionAbout from './components/section/SectionAbout';
import SectionWebApp from './components/section/SectionWebApp';
import SectionTechno from './components/section/SectionTechno';
import SectionWorkflow from './components/section/SectionWorkflow';
import SectionDesign from './components/section/SectionDesign';
import SectionContact from './components/section/SectionContact';
import SectionContactForm from './components/section/SectionContactForm';
// Importing app chat
import AppCookie from './components/app/AppCookie';
import AppChat from './components/app/AppChat';
// Importing footer
import Footer from './components/footer/footer';

function App() {
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const { i18n, t } = useTranslation(); // Use `t` to access translations

  // Read the language from localStorage or use 'fr' by default
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'fr';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  // Dynamically update the `lang` attribute of the <html> tag
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  // Dynamically update the meta description
  useEffect(() => {
    const description = t('description'); // Fetch the description from the translation files
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }
  }, [i18n.language, t]);

  // Check cookie preferences
  useEffect(() => {
    const cookiePreference = Cookies.get('cookieAccepted');
    if (cookiePreference === undefined || cookiePreference === 'null') {
      setShowCookieBanner(true);
    }
  }, []);

  return (
    <>
      {showCookieBanner && <AppCookie />}
      <Navbar />
      <AppChat />
      <SectionCover />
      <SectionAbout />
      <SectionWebApp />
      <SectionTechno />
      <SectionWorkflow />
      <SectionDesign />
      <SectionContact />
      <SectionContactForm />
      <Footer />
    </>
  );
}

export default App;