import { useEffect } from 'react';
import './AppCookie.css';
import useCookie from '../hooks/useCookie';
import { useTranslation } from 'react-i18next';

function loadGoogleAnalytics() {
    var script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-BYW2G3NH00";
    script.async = true;
    document.head.appendChild(script);

    script.onload = function () {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-BYW2G3NH00');
    };
}

function AcceptCookie() {
    console.log('Cookie accepté');
    loadGoogleAnalytics();
}

function RefuseCookie() {
    console.log('Cookie refusé');
}

function AppCookie() {
    const { t } = useTranslation();
    const [isCookieAccepted, setIsCookieAccepted] = useCookie('cookieAccepted'); // Utilisation du hook

    useEffect(() => {
        if (isCookieAccepted === 'true') {
            loadGoogleAnalytics();
        }
    }, [isCookieAccepted]);

    const handleAccept = () => {
        AcceptCookie();
        setIsCookieAccepted('true'); // Met à jour le cookie avec "true"
        handleDelete();
    };

    const handleRefuse = () => {
        RefuseCookie();
        setIsCookieAccepted('false', { expires: 1 }); // Met à jour le cookie avec "false"
        handleDelete();
    };

    const handleDelete = () => {
        let cookieBanner = document.querySelector('.cookie');
        cookieBanner.classList.toggle('delete');
    };

    return (
        <section className="cookie_container">
            <div className="cookie">
                {isCookieAccepted === null || isCookieAccepted === 'null' ? (
                    <>
                        <p>
                            {t('j5k6l7m8n9')}
                        </p>
                        <img src="/svg/icon_cookie_valid.svg" alt="Accept cookies" onClick={handleAccept} />
                        <img src="/svg/icon_cookie_refuse.svg" alt="Refuse cookies" onClick={handleRefuse} />
                    </>
                ) : isCookieAccepted === 'true' ? (
                    <p>
                        {t('y5z6a7b8c9')}
                    </p>
                ) : (
                    <p>
                        {t('e5f6g7h8i9')}
                    </p>
                )}
            </div>
        </section>
    );
}

export default AppCookie;
