import React from 'react';
import { useTranslation } from 'react-i18next';
import './AppLanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  // Function to change the language and refresh the page
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    window.location.reload(); // Refresh the page
  };

  return (
    <div className='LanguageSwitcher'>
      <div className='LanguageSwitcher_container'>
        <img src="/svg/icon_lang.svg" alt="Language" />
        <button onClick={() => changeLanguage('en')}>En</button>
        <button onClick={() => changeLanguage('fr')}>Fr</button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;