import './SectionWorkflow.css';
import { useTranslation } from 'react-i18next';

function SectionWorkflow() {
    const { t } = useTranslation();
    return (
        <section className='section_workflow'>
            <div className='section_workflow-container'>
                <h2 className='section_workflow_container-title'>
                    {t('b3c4d5e6f7')}
                </h2>
                <p className='section_workflow_container-subtitle'>
                    {t('l3m4n5o6p7')}
                </p>
                <div className='section_workflow_container-graph'>
                    <img src="/svg/graph_road_1.svg" alt="graphique of workflow" />
                    <img src="/svg/graph_road_2.svg" alt="graphique of workflow" />
                    <img src="/svg/graph_road_3.svg" alt="graphique of workflow" />
                    <img src="/svg/graph_road_4.svg" alt="graphique of workflow" />
                </div>
            </div>
        </section>
    )
}

export default SectionWorkflow;