import './SectionTechno.css';
import { useTranslation } from 'react-i18next';

function SectionTechno() {
    const { t } = useTranslation();
    return (
        <section className='section_techno_container'>
            <div className='section_techno_container-title_container'>
                <h2 className='section_techno_container-title'>
                    {t('s1t2u3v4w5')}
                </h2>
                <p className='section_techno_containe-subtitle'>
                    {t('c1d2e3f4g5')}

                </p>
                <div className='section_techno_container-icon_container'>
                    <div>
                        <img src="/svg/icon_react.svg" alt="react icon" />
                        <p>
                            {t('m1n2o3p4q5')}

                        </p>
                    </div>
                    <div className='mobile-none'>
                        <img src="" alt="" />
                        <hr style={{ width: "100%" }} />
                    </div>
                    <div>
                        <img src="/svg/icon_nodejs.svg" alt="nodeJs icon" />
                        <p>
                            {t('g2h3i4j5k6')}

                        </p>
                    </div>
                    <div className='mobile-none'>
                        <img src="" alt="" />
                        <hr style={{ width: "100%" }} />
                    </div>
                    <div>
                        <img src="/svg/icon_mongodb.svg" alt="MongoDB icon" />
                        <p>
                            {t('q2r3s4t5u6')}

                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionTechno;