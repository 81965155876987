import './SectionContact.css';
import ButtonContact from '../button/ButtonContact';
import { t } from 'i18next';

function SectionContact() {
    return (
        <section id='contact' className='section_contact'>
            <div className='section_contact_container'>
                <h2 className='section_contact_container_title'>
                    {t('h3i4j5k6l7')}
                </h2>
                <p className='section_contact_container_subtitle'>
                    {t('p3q4r5s6t7')} <span>70</span>min
                </p>
                <div className='section_contact_container_2'>
                    <div>
                        <div>
                        {t('e1f2g3h4i5')}
                        </div>
                        <div className='section_contact_container_2_job_subtitle'>
                        {t('o1p2q3r4s5')}
                        </div>
                    </div>
                    <div>
                        <ButtonContact />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionContact;